<template>
  <div>
    <div class="search-box">
      <div class="tabs">
        <el-row>
          <el-col :span="3"><span class="title">专业门类></span></el-col>
          <el-col :span="21">
            <span :class="{
              item: true,
              act: levelId == null
            }" @click="allSpecialty()">全部</span>

            <el-badge :value="counts[item.specialty_code]" class="item" v-for="(item, i) in level" :key="i"
              type="primary">
              <span :class="{
                act: levelId == item.specialty_code
              }" @click="getChildren(item)">
                {{ item.name }}
              </span>
            </el-badge>


          </el-col>
        </el-row>

        <el-divider class="search_line-condition"></el-divider>
        <el-row>
          <el-col :span="3"><span class="title">专业大类></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, }" @click="allChild">全部</span>
            <el-badge :value="counts1[item.specialty_code]" v-for="(item, i) in children" :key="i" class="item"
              type="primary">
              <span :class="{ act: childId == item.specialty_code }" @click="getContent(item)">{{ item.name }}</span>
            </el-badge>
          </el-col>
        </el-row>

        <el-divider class="search_line-condition"></el-divider>
        <el-row>
          <el-col :span="3"><span class="title">专业名称></span></el-col>
          <el-col :span="21">
            <span :class="{ item: true, }" @click="allChilds()">全部</span>
            <span v-for="(item, i) in childrenList" :key="i"
              :class="{ act: CodeList.indexOf(item.specialty_code) > -1 }" @click="getSpeclatyCode(item)">{{
                item.name
              }}</span>
          </el-col>
        </el-row>

        <el-divider class="search_line-condition"></el-divider>
        <el-row>
          <el-col :span="3">
            <span class="title">推荐志愿></span>
          </el-col>
          <el-col :span="21">
            <span :class="{ item: true, act: check.level === null }" @click="choose('level', null, 0)">全部</span>
            <span :class="{ item: true, act: check.level === 1 }" style="color: #BB0000;"
              @click="choose('level', 1, 0)">难</span>
            <span :class="{ item: true, act: check.level === 2 }" @click="choose('level', 2, 0)">冲一冲</span>
            <span :class="{ item: true, act: check.level === 3 }" @click="choose('level', 3, 0)">稳一稳</span>
            <span :class="{ item: true, act: check.level === 4 }" @click="choose('level', 4, 0)">保一保</span>
          </el-col>
        </el-row>
      </div>
      <div class="specialty-search tianbao-search">
        <el-autocomplete v-model="specialty_name" style="width: 280px;float: right;"
          :fetch-suggestions="querySearchAsync" size="mini" placeholder="请输入专业名称" @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
          <el-button slot="append" class="btn" size="mini" type="primary">搜索</el-button>
        </el-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "",
  props: {
    value: Object,
    num: Number,
    new_batch: String
  },
  data() {
    return {
      check: {

      },
      specialty_name: '',
      levelId: null,
      childId: null,

      level: [],
      children: [],
      childrenList: [],
      loading: false,
      CodeList: [],

      NumList: [],
      NumList1: [],
      counts: [],
      counts1: [],
      status1: false,
      status2: false
    };
  },
  watch: {
    new_batch() {
      this.CodeList = []
      this.getLevel()
    }
  },
  created() {
    this.check = this.value
    this.getLevel();
  },


  methods: {
    doSearch() {
      this.$emit("onSearch", this.check);
    },

    //选择条件
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },

    //获取门类列表
    getLevel() {
      console.log(this.new_batch)
      let id = 0
      if (this.new_batch == 1) {
        id = 0
      } else {
        id = 1
      }
      this.$fecth.post("Specialty/getSpecialty", { degree: id }).then((res) => {
        this.level = res;
        this.getChildren(res[0]);
      });
    },


    // 获取大类
    getChildren(item) {
      this.levelId = item.specialty_code;
      this.loading = true;
      this.$fecth
        .post("Specialty/getSpecialtyChild", {
          specialty_code: item.specialty_code,
        })
        .then((res) => {
          this.children = res;
          this.childrenList = this.children[0].childrens;
          this.childId = this.children[0].specialty_code;
          this.loading = false;
        });
    },
    //获取专业
    getContent(item) {
      this.childId = item.specialty_code;
      this.childrenList = item.childrens;
    },

    getSpeclatyCode(item) {
      if (this.CodeList.indexOf(item.specialty_code) > -1) {
        this.CodeList.splice(this.CodeList.indexOf(item.specialty_code), 1);
      } else {
        this.CodeList.push(item.specialty_code)
      }
      this.getParent()
      this.check.code_list = this.CodeList
      this.$emit("onSearch", this.check);
    },

    getParent() {
      this.NumList = []
      this.NumList1 = []
      this.CodeList.forEach((item) => {
        this.NumList.push(item.substring(0, 2))
        this.NumList1.push(item.substring(0, 4))
      })
      this.countDuplicates()
    },

    countDuplicates() {
      let counts = {};
      let counts1 = {};
      this.NumList.forEach(item => {
        if (counts[item]) {
          counts[item]++;
        } else {
          counts[item] = 1;
        }
      });
      this.NumList1.forEach(item => {
        if (counts1[item]) {
          counts1[item]++;
        } else {
          counts1[item] = 1;
        }
      });
      this.counts = counts
      this.counts1 = counts1
    },


    allSpecialty() {
      this.levelId = null
      this.CodeList = []
      this.check.code_list = this.CodeList
      this.getParent()
      this.$emit("onSearch", this.check);
    },

    allChild() {
      if (this.status1) {
        this.children.forEach(item => {
          item.childrens.forEach(val => {
            if (this.CodeList.indexOf(val.specialty_code) > -1) {
              this.CodeList.splice(this.CodeList.indexOf(val.specialty_code), 1);
              this.CodeList.push(val.specialty_code)
            } else {
              this.CodeList.push(val.specialty_code)
            }

          })
        })
      } else {
        this.children.forEach(item => {
          item.childrens.forEach(val => {
            if (this.CodeList.indexOf(val.specialty_code) > -1) {
              this.CodeList.splice(this.CodeList.indexOf(val.specialty_code), 1);
            }
          })
        })
      }
      this.status1 = !this.status1
      this.check.code_list = this.CodeList
      this.getParent()
      this.$emit("onSearch", this.check);
    },

    allChilds() {
      if (this.status2) {
        this.childrenList.forEach(item => {
          if (this.CodeList.indexOf(item.specialty_code) > -1) {
            this.CodeList.splice(this.CodeList.indexOf(item.specialty_code), 1);
            this.CodeList.push(item.specialty_code)
          } else {
            this.CodeList.push(item.specialty_code)
          }
        })
      } else {
        this.childrenList.forEach(item => {
          if (this.CodeList.indexOf(item.specialty_code) > -1) {
            this.CodeList.splice(this.CodeList.indexOf(item.specialty_code), 1);
          }
        })
      }
      this.status2 = !this.status2
      this.check.code_list = this.CodeList
      this.getParent()
      this.$emit("onSearch", this.check);
    },
    querySearchAsync(queryString, cb) {

      if (queryString) {
        this.$fecth
          .post("volunteer_demand/getSpecialtyList", {
            name: queryString,
            degree: 0,
          })
          .then((res) => {
            var results = res

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              cb(results);
            }, 3000 * Math.random());
          });
      }
    },

    handleSelect(item) {
      this.getSpeclatyCode(item)
    }

  },
};
</script>

<style lang="less">
.el-badge__content.is-fixed {
  right: 25px;
}
</style>

<style scoped lang='less'>
.old_search {
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 3px 7px;
  border-radius: 10px;
  font-size: 12px;
  margin-right: 10px;
}

.search-box {
  margin: 0 0 20px;
  position: relative;

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }


  .tabs {
    color: #666666;
    letter-spacing: 2px;
    padding: 15px 0 0 0;
    margin-bottom: 15px;
    font-size: 16px;
    position: relative;

    .title {
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 6px;
      line-height: 27px;
      margin-bottom: 3px;
      margin-right: 12px;

      &:hover {
        background: #2B7BF3;
        color: white;
      }
    }
  }

  .act {
    background-color: #2B7BF3;
    color: #ffffff;
  }

  .act1 {
    background-color: #bd01ad;
    color: #ffffff;
  }
}

.search_line-condition {
  margin: 7px 0;
}

.tianbao-search {
  height: 25px;
  font-size: 16px;

  .btn {
    background: #2B7BF3;
    color: white;
    border-radius: 0 3px 3px 0;
    border: 1px solid #2B7BF3;
  }
}
</style>
