<template>
  <div style="padding-bottom: 40px">
    <div class="plan-page">
      <div class="search-filter">
        <el-row>
          <el-col :span="3">
            <span class="title">
              招生计划
            </span>
          </el-col>
          <el-col :span="20">
            <el-select v-model="year" style="width:120px;margin-right: 20px;" size="mini" class="round_select"
              placeholder="请选择" @change="onChange">
              <el-option v-for="item in yearList" :key="item" :label="item" :value="item" />
            </el-select>
            <el-select v-model="batch" style="width:130px" size="mini" class="round_select" placeholder="请选择"
              @change="onChange">
              <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-col>
        </el-row>
      </div>


      <div class="college-item">
        <span class="title">招生单位</span>
        <span class="school_name" @click="changeCollege('', null)" :class="{ hove: nav == null }">全部</span>
        <span class="school_name" v-for="(item, i) in NavList" :class="{ hove: nav == i }" :key="i"
          @click="changeCollege(item, i)">{{ item.school_id }} &nbsp;{{ item.school_name }}</span>
      </div>


      <div class="table">
        <el-table v-if="tableData" v-loading="loading"
          :header-cell-style="{ 'color': 'black', 'letter-spacing': '1px', 'font-size': '18px', 'font-weight': '400' }"
          :cell-style="{ padding: '4px 0', 'color': 'black' }" :data="tableData"
          style="width: 100%;font-size:16px;text-align:center">
          <el-table-column prop="specialty_id" width="888" label="专业名称">
            <template slot-scope="scope">
              <div class="specialty_name">
                <span>{{ scope.row.specialty_id | interpo }} </span>
                <span>{{ scope.row.specialty_name }}</span>
              </div>
              <div class="remarks">{{ scope.row.remarks }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="plan_num" align="center" label="专业计划" />
          <el-table-column prop="tuition" align="center" label="收费标准">
            <template slot-scope="scope">
              {{ scope.row.tuition | tuitionFilter }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="font-size:12px;margin:20px 0;color: gray;">备注：以上招生计划仅供参考，最终填报志愿请以河南省教育考试院公布代码和数据为准</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GetPlant",
  computed: { ...mapState(["userInfo"]) },
  data() {
    return {
      tableData: [],
      batch: "1",
      loading: false,
      batchList: [
        { id: "1", name: "本科一批", code: "1", status: "1" },
        { id: "2", name: "本科二批", code: "2", status: "2" },
        { id: "3", name: "高职高专批", code: "3", status: "3" },
        { id: "4", name: "其他批次", code: "4", status: "4" },
      ],
      year: "2024", // 年份
      yearList: [
        "2025",
        "2024",
        "2023",
        "2022",
      ],
      NavList: null,
      school_id: null,
      select_code: null,
      nav: null
    };
  },
  filters: {
    tuitionFilter(e) {
      if (e > 0) {
        return e + '元/年'
      } else {
        return '学费暂无'
      }
    }
  },
  mounted() {
    this.getNav()
    // 获取批次/科类列表
    // this.getPlan();
  },
  methods: {
    getPlan() {
      if (!this.select_code) {
        this.loading = false
        return false
      }
      this.loading = true
      this.$fecth
        .get("enrollment_plan/getData", {
          params: {
            pageSize: 100,
            select_code: this.select_code,
            is_wenli: this.is_wenli,
            batch: this.batch,
            year: this.year,
          },
        })
        .then((res) => {
          this.loading = false
          this.tableData = res.lists;
        });
    },
    getNav() {
      this.school_id = this.$route.query.sid
      if (!this.school_id) {
        return;
      }
      this.scoreLine = [];
      this.$fecth
        .get("show_school_score/getNav", {
          params: {
            batch: this.batch,
            is_wenli: this.userInfo.is_wenli,
            school_id: this.school_id,
          },
        })
        .then((res) => {
          if (res.length > 0) {
            this.NavList = res;
            if (this.NavList[0]) {
              this.select_code = this.NavList.map(row => row['select_code'])
              this.batch = String(this.NavList[0].batch);
              this.getPlan();
            }
          }
        });
    },
    //招生单位选择
    changeCollege(item, i) {
      if (item) {
        this.nav = i
        this.select_code = item.select_code
      } else {
        this.nav = null
        if (this.NavList) {
          this.select_code = this.NavList.map(row => row['select_code'])
        }
      }
      this.getPlan()
    },
    onChange() {
      this.getPlan();
    },
  },

};
</script>

<style lang="less">
.plan-page {
  .search-filter {
    .el-input--mini .el-input__inner {
      background: #F8F8F8;
      border: 0;
      font-size: 16px;
      color: black;
    }

    .el-select .el-input .el-select__caret {
      color: black;
    }
  }
}
</style>

<style lang="less" scoped>
.plan-page {
  width: 1200px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding-top: 38px;

  .title {
    margin-top: 30px;
    font-size: 22px;
    color: #4591F6;
  }

  .college-item {
    margin-top: 40px;
    margin-bottom: 10px;


    .title {
      font-size: 18px;
      font-weight: normal;
      line-height: 18px;
      color: black;
      margin-right: 30px;

    }

    .school_name {
      color: #A7A7A7;
      border: 1px solid #A7A7A7;
      margin-right: 20px;
      font-size: 18px;
      padding: 2px 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      display: inline-block;
    }

    .hove {
      color: #4591F6;
      border: 1px solid #EFF5FF;
      background: #EFF5FF;

    }
  }

  .table {
    margin: 15px 0 0;

    .specialty_name {
      font-size: 18px;
      color: #000000;
      margin-bottom: 12px;
      margin-top: 24px;
    }

    .remarks {
      font-size: 16px;
      color: #000000;
      margin-bottom: 18px;
    }
  }
}
</style>
