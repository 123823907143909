<template>
  <div class="specialty-page">
    <div class="specialty">
      <CollegeSpecialty></CollegeSpecialty>
    </div>
    <div class="demand">
      <CollegeDemand></CollegeDemand>
    </div>

    <div class="shuangyiliu" v-if="shuangState && degree == 0">
      <CollegeShuang @getshuangState="getshuangState"></CollegeShuang>
    </div>
    <div class="shuangyiliu" v-if="ImportState && degree == 0">
      <CollegeImportant @getImportState="getImportState"></CollegeImportant>
    </div>
    <div class="shuangyiliu" v-if="zhongwaiState && degree == 0">
      <CollegeShuangZhongwai @getzhongwaiState=getzhongwaiState></CollegeShuangZhongwai>
    </div>
    <div class="specialty" style="margin-top: 8px;" v-if="degree == 0">
      <CollegeSpecialtyRanking></CollegeSpecialtyRanking>
    </div>
    <div class="demand" style="margin-top: 8px;" v-if="degree == 0">
      <CollegeSpecialtyAssess></CollegeSpecialtyAssess>
    </div>

    <div class="shuangyiliu" style="margin-top: 8px;" v-if="degree == 1">
      <CollegeSpecialtyZhuan></CollegeSpecialtyZhuan>
    </div>

  </div>
</template>

<script>
import CollegeSpecialty from './components/CollegeSpecialty.vue';
import CollegeDemand from './components/CollegeDemand.vue';
import CollegeShuang from './components/CollegeShuang.vue';
import CollegeImportant from './components/CollegeImportant.vue';
import CollegeSpecialtyRanking from './components/CollegeSpecialtyRanking.vue';
import CollegeSpecialtyAssess from './components/CollegeSpecialtyAssess.vue';
import CollegeShuangZhongwai from './components/CollegeShuangZhongwai.vue';
import CollegeSpecialtyZhuan from './components/CollegeSpecialtyZhuan.vue';

export default {
  name: '',
  components: {
    CollegeSpecialty,
    CollegeDemand,
    CollegeShuang,
    CollegeImportant,
    CollegeSpecialtyRanking,
    CollegeSpecialtyAssess,
    CollegeShuangZhongwai,
    CollegeSpecialtyZhuan
  },
  data() {
    return {
      shuangState: true,
      ImportState: true,
      zhongwaiState: true,
      zhuanState:true,
      degree: 0
    };
  },
  created() {
    this.$parent.cat = 1
    this.degree = this.$route.query.degree

  },
  computed: {
  },
  methods: {
    getshuangState(e) {
      this.shuangState = e
    },
    getImportState(e) {
      this.ImportState = e
    },
    getzhongwaiState(e) {
      this.zhongwaiState = e
    },
    getZhuanState(e) {
      this.zhuanState = e
    }
  },
};
</script>

<style scoped lang='less'>
.specialty-page {
  width: 1400px;
  margin: 0 auto;
  padding-bottom: 40px;
  vertical-align: top;

  .specialty {
    background: white;
    height: 640px;
    width: 759px;
    padding-left: 100px;
    display: inline-block;
    margin-right: 7px;
    vertical-align: top;
    padding-top: 30px;
  }

  .demand {
    background: white;
    height: 650px;
    width: 433px;
    padding-right: 100px;
    display: inline-block;
    vertical-align: top;
    padding-top: 20px;

  }

  .shuangyiliu {
    background: white;
    margin-top: 8px;
    padding: 30px 100px;
  }
}
</style>
