<template>
  <div class="tableData">
    <div v-if="status == 0">
      <div class="title">
        <span>添加方案</span>
      </div>
      <div style="height: 8px; margin-top: 20px; margin-right: 20px">
        <el-select v-model="batch" placeholder="请选择批次" class="round_select" @change="onBatchChange()" size="mini">
          <el-option v-for="item in batchList" :key="item.value" :label="item.name" :value="item.value" />
        </el-select>
      </div>

      <div class="table">
        <el-row type="flex" align="middle">
          <el-col :span="24">
            <table border v-for="(item, i) in table" :key="i">
              <tbody>
                <tr rowspan="7" class="th">
                  <td rowspan="7" style="width: 130px; background: white">
                    <div class="px">平行志愿{{ word[i] }}</div>
                    <div>
                      <div class="fc" v-if="item.college.tiaoji">服从调剂</div>
                      <div class="fc" v-else style="color: red">拒绝调剂</div>
                    </div>

                    <div class="edit">
                      <el-button round size="mini" @click="handEditCollege(item)">编辑</el-button>
                    </div>
                  </td>
                  <td colspan="2">
                    <span class="name">{{ item.college.name }}</span>
                    <span>招生代号：{{ item.college.school_id }} </span>
                  </td>
                </tr>
                <tr>
                  <td class="th" style="width: 100px">专业序号</td>
                  <td class="th">专业代号/名称</td>
                </tr>
                <tr v-for="(val, key) in item.specialty" :key="key">
                  <td>专业{{ word[key] }}</td>
                  <td>
                    [{{ val.specialty_id | interpo }}]{{ val.specialty_name }}
                  </td>
                </tr>

                <!-- 没有的填充一下 -->
                <tr v-for="test in 5 - item.specialty.length" :key="test + i + 'dfd'">
                  <td style="height: 35px"></td>
                  <td style="height: 35px"></td>
                </tr>
              </tbody>
            </table>
          </el-col>
        </el-row>
      </div>

      <div class="remarks">
        <div class="title">我的想法</div>
        <div class="remarks_input">
          <el-input :rows="5" type="textarea" placeholder="请输入内容" v-model="remarks" show-word-limit maxlength="200">
          </el-input>
        </div>
      </div>

      <div class="btn">
        <el-button round @click="save">保存当前信息</el-button>
        <el-button round @click="handAdd" type="success">发送至专家审核</el-button>
      </div>
    </div>

    <div v-if="status == 1">
      <img style="width:100%" src="@/assets/img/review/result.jpg" alt="">
    </div>

    <div v-if="status == 2">
      <div class="table">
        <div class="tltle">回复方案</div>
        <div v-for="(item, i) in r_table" :key="i">
          <table border>
            <tbody>
              <tr rowspan="7" class="th">
                <td rowspan="7" style="width:130px; background:white">
                  <div class="px">
                    平行志愿{{ word[i] }}
                  </div>
                </td>
                <td colspan="5"> <span class="name">{{ item.college.name }}</span>
                </td>
              </tr>
              <tr>
                <td class="th" style="width:100px">专业序号</td>
                <td class="th">专业代号/名称</td>
              </tr>
              <tr v-for="( val, key) in item.specialty" :key="key">
                <td>专业{{ word[key] }}</td>
                <td>[{{ val.specialty_id | interpo }}]{{ val.specialty_name }}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <el-input v-model="r_remarks[i]" disabled placeholder="此处添加备注信息"></el-input>
          </div>
        </div>

        <div class="user">
          <div class="title">
            学员想法
          </div>
          <div class="remarks">
            {{ remarks }}
          </div>
        </div>
        <div class="user">
          <div class="title">
            老师回复
          </div>
          <el-input type="textarea" v-model="reply" disabled style="margin-top:10px" />
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" class="cre" :title="title" width="680px" append-to-body destroy-on-close
      :before-close="handleClose">
      <EditCollege @test="close" v-if="dialogVisible" :itemData="itemData" :batch="batch"></EditCollege>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
import EditCollege from "./EditCollege.vue";
export default {
  name: "",
  data() {
    return {
      table: [],
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", '十一', '十二'],
      len: 12,
      batch: 1,
      dialogVisible: false,
      batchList: [
        { name: "本科一批", value: 1 },
        { name: "本科二批", value: 2 },
        { name: "高职高专批", value: 3 },
      ],
      itemData: [],
      title: "",
      remarks: "",
      status: 0,
      reply: '',
      r_remarks: [],
      r_table: []
    };
  },
  components: {
    EditCollege,
  },
  created() {
    this.getDataById();
  },
  computed: {
    ...mapState(["userInfo"]),
  },

  methods: {
    getDataById() {
      this.$fecth.post("/review/getDataById").then((res) => {
        console.log(res)
        if (res) {
          this.table = JSON.parse(res.table);
          this.r_table = JSON.parse(res.r_table);
          this.reply = res.reply;
          this.batch = res.batch;
          this.r_remarks = JSON.parse(res.r_remarks);
          this.status = res.status;
        } else {
          this.getTable();
        }
      });
    },
    onBatchChange() {

      this.getTable();

      this.$forceUpdate();
    },
    handEditCollege(item) {
      this.title = "选择院校-专业";
      this.itemData = item;
      this.$forceUpdate();
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.itemData = [];
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    getTable() {
      this.table = [];
      for (let i = 0; i < this.len; i++) {
        this.table[i] = {
          college: {
            name: "",
          },
          specialty: [],
        };
      }
    },
    save(state = 1) {
      let data = {
        batch: this.batch,
        is_wenli: this.userInfo.is_wenli,
        score: this.userInfo.score,
        table: JSON.stringify(this.table),
        remarks: this.remarks,
      };

      this.$fecth.post("/review/SaveReview", data).then(() => {
        if (state) {
          this.$notify({
            showClose: true,
            message: "保存成功",
            type: "success",
          });
        }
      });
    },
    handAdd() {
      this.save(0);
      this.$confirm("是否现在提交专家审核，信息提交后将无法修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.getOrder();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getOrder() {
      this.$fecth.post("/review/getOrder").then((res) => {
        if (res) {
          this.$fecth.post("/review/add").then((res) => {
            if (res) {
              this.$notify({
                showClose: true,
                message: "提交成功",
                type: "success",
              });
            }
          });
        } else {
          this.$confirm("还未购买。请先购买", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/goodsInfo?id=4");
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.tableData {
  background: white;
  margin-top: 10px;

  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
}

.round_select {
  float: right;
}

.table {
  padding: 20px;

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}

.edit {
  text-align: center;
  margin-bottom: 10px;
}

.remarks {
  .remarks_input {
    padding: 20px;
  }
}

.btn {
  padding: 20px 30%;

  button:last-child {
    margin-left: 100px;
  }
}
</style>
