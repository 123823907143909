<template>
  <div class="report">

    <div class="filter">
      位置筛选
      <ul>
        <li :class="{ level: level == 0 }" @click="changeLevel(0)">1-5</li>
        <li :class="{ level: level == 1 }" @click="changeLevel(1)">6-10</li>
        <li :class="{ level: level == 2 }" @click="changeLevel(2)">11-15</li>
        <li :class="{ level: level == 3 }" @click="changeLevel(3)">16-20</li>
        <li :class="{ level: level == 4 }" @click="changeLevel(4)">21-25</li>
        <li :class="{ level: level == 5 }" @click="changeLevel(5)">26-30</li>
        <li :class="{ level: level == 6 }" @click="changeLevel(6)">31-35</li>
        <li :class="{ level: level == 7 }" @click="changeLevel(7)">36-40</li>
        <li :class="{ level: level == 8 }" @click="changeLevel(8)">41-45</li>
        <!-- <li @click="exportPDF">导出</li> -->
      </ul>
    </div>

    <div class="table">
      <el-row type="flex" align="middle" v-if="reportTable">
        <el-col :span="24">
          <table border v-for="(item, i) in reportTable" :key="i">
            <tbody>
              <tr rowspan="8" class="th">
                <td rowspan="8" style="width: 130px; background: white">
                  <div class="px">
                    平行志愿{{ i + 1 + level * 5 }}
                  </div>
                  <div v-if="item.name == '暂无'">
                  </div>
                  <div v-else>
                    <div class="fc" v-if="item.tiaoji">服从调剂</div>
                    <div class="fc" v-else style="color: red">拒绝调剂</div>
                  </div>

                  <div class="del" v-if="item">
                    <el-button round size="mini" type="success" plain v-if="item.name && item.name != '暂无'"
                      @click="editCollege(item, i + level * 5)">编辑</el-button>
                  </div>

                  <div class="del" v-if="item">
                    <el-button round size="mini" type="danger" plain @click="delCollege(item, i)">删除</el-button>
                  </div>
                  <div class="huanwei" v-if="item">
                    <el-popover popper-class="popover-f" placement="bottom" trigger="hover">
                      <div class="popover-box">
                        <p class="box-title">志愿位置</p>
                        <span v-for="h in len" :key="h" class="box-item" @click="changeCollege(h - 1, i + level * 5)">
                          <span v-if="record.select_code[h - 1] != ''" class="box-item-box"
                            style="background: #fbbfbb; border: #fbbfbb;">{{ h }}</span>
                          <span v-else class="box-item-box">{{ h }}</span>
                        </span>
                      </div>
                      <!-- <el-button round size="mini" slot="reference" type="primary" plain
                        style="position: relative; left: 7px;">换位</el-button> -->
                    </el-popover>
                  </div>



                </td>
                <td colspan="7">
                  <span class="name">{{ item.name }}</span>
                  <span>所在地：{{ item.city_name }} </span>
                  <span>招生代号：{{ item.school_id }} </span>
                  <span>院校计划：{{ item.plan_num }} </span>
                  <span>录取规则：{{ item.demand }}</span>
                </td>
              </tr>
              <tr>
                <td class="th" style="width: 100px">专业序号</td>
                <td class="th">专业代号/名称</td>
                <td class="th" style="width: 100px">专业详情</td>
                <td class="th" style="width: 100px">专业计划</td>
                <td class="th" style="width: 100px">学费标准</td>
              </tr>
              <tr v-for="(val, key) in record.specialty[i + level * 5]" :key="key">
                <td class="td-height">-</td>
                <td class="td-height">{{ val.specialty_name }} </td>
                <td class="td-height">-</td>
                <td class="td-height">-</td>
                <td class="td-height">-</td>
              </tr>

              <tr v-for="test in 6 - record.specialty[i + level * 5].length" :key="test + i + 'dfd'">
                <td style="height: 45px"></td>
                <td style="height: 45px"></td>
                <td style="height: 45px"></td>
                <td style="height: 45px"></td>
                <td style="height: 45px"></td>
              </tr>
            </tbody>
          </table>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" v-if="!reportTable">
        <el-col :span="24">
          <div style="margin-top: 20px; text-align: center;"> 暂无志愿表</div>
        </el-col>
      </el-row>
    </div>

    <el-dialog :visible.sync="dialogVisible" class="mojarDialog" width="1400px" :show-close="false"
      :append-to-body="true" :close-on-click-modal="false" destroy-on-close>
      <div style="position: absolute;
    right: 10px;
    z-index: 999;
    top: -4px;
    font-size: 40px;
    font-weight: 100;" @click="colse()">×</div>
      <mojar-page :collegeDatas="collegeData" :oldLine="oldLine" :userInfo="userInfo" :record="record"
        v-if="dialogVisible" />
    </el-dialog>


  </div>
</template>

<script>
import { mapState } from "vuex";
import mojarPage from "./components/mojar_page/index.vue";
export default {
  name: '',
  data() {
    return {
      nav: 2,
      level: 0,
      new_batch: 1,
      record: {

      },
      oldLine: {},
      reportTable: {},
      len: 45,
      dialogVisible: false,
      collegeData: null,
    };
  },
  computed: { ...mapState(["userInfo"]) },
  components: {
    mojarPage,
  },
  created() {


    this.getOldCityScore()
    this.getReport()
  },
  methods: {

    // 根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          this.oldLine = res
        });
    },

    getReport() {
      let data = {
        user_id: this.userInfo.id,
        subject: this.userInfo.subject,
        new_batch: this.new_batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        if (res.record) {
          this.record = JSON.parse(res.record)
          console.log(this.record)
          this.reportTable = this.record.table.slice(0, 5)
        }
      })
    },

    editCollege(item, i) {
      this.collegeData = item
      console.log(item, i)
      this.dialogVisible = true
    },

    delCollege(item) {
      if (item.select_code) {
        let select_code_id = this.record.select_code.indexOf(item.select_code)
        //如果这个学校填过就删除
        if (select_code_id > -1) {
          this.record.table[select_code_id] = []
          this.record.select_code[select_code_id] = []
          this.record.specialty[select_code_id] = []
        }
        this.updateReport(this.record)
        this.changeLevel(this.level)
      }
    },

    changeCollege(h, i) {
      this.record.table = this.moveElementInArray(this.record.table, i, h)
      this.record.select_code = this.moveElementInArray(this.record.select_code, i, h)
      this.record.specialty = this.moveElementInArray(this.record.specialty, i, h)
      this.updateReport(this.record)
      this.changeLevel(this.level)
    },

    moveElementInArray(array, fromIndex, toIndex) {
      const element = array.splice(fromIndex, 1)[0];
      array.splice(toIndex, 0, element);
      return array
    },

    updateReport(record) {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.userInfo.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(record)
        })
    },
    changeLevel(e) {
      this.level = e
      let num = e * 5
      this.reportTable = this.record.table.slice(num, num + 5)
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },

    colse() {
      this.dialogVisible = false
    },
    // exportPDF() {
    //   this.$router.push(
    //     "/report?id=" +
    //     this.userInfo.id
    //   );
    // }
  },
};
</script>

<style scoped lang='less'>
.report {
  width: 1200px;
  margin: 0 auto;
  background: white;
}

.filter {
  padding: 0 52px;
  margin-top: 15px;

  ul {
    display: inline-block;
    list-style: none;
    position: relative;
    top: 7px;
  }

  li {
    float: left;
    width: 60px;
    text-align: center;
    margin-left: 20px;
    border: 1px solid #707070;
    border-radius: 19px;
    padding: 2px 13px;
  }

  li:hover {
    background: #459df6;
    color: white;
    border: #459df6;
  }

  .level {
    background: #459df6;
    color: white;
    border: #459df6;
  }
}

.top {
  margin-top: 20px;
  border-bottom: 4px solid #459EFF;
  height: 40px;
}

.right {
  float: right;
  height: 36px;

  ul {
    display: inline-block;
    list-style: none;
  }

  li {
    float: left;
    margin-left: 10px;
    height: 36px;
    padding: 17px;
    line-height: 3px;
    border-radius: 5px 5px 0 0;
  }

  li:hover {
    color: white;
    background: #459EFF;
    border-bottom: 1px solid #459EFF;
  }

}

.nav {
  color: white;
  background: #459EFF;
  border-bottom: 1px solid #459EFF;
}





.table {
  margin: 0 auto;

  table {
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .th {
      font-size: 16px;
      background: #f4f7fc;
      line-height: 35px;

      .name {
        font-size: 18px;
        font-weight: bold;
      }

      span {
        margin-right: 15px;
      }

      .col {
        margin-left: 0;
        color: #459df6;
      }
    }

    .th,
    td {
      line-height: 35px;
      padding: 5px 13px;
    }
  }

  .del {
    text-align: center;
    margin-bottom: 10px;
  }

  .huanwei {
    text-align: center;
    margin-bottom: 10px;
  }
}

.px {
  margin-top: 20px;
  text-align: center;
  font-size: 14;
  margin-bottom: 10px;
}

.fc {
  text-align: center;
  color: #459df6;
  margin-bottom: 10px;
}
</style>


<style lang="less">
.mojarDialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>