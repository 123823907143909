<template>
  <div class="college-demand">
    <div>
      <div class="title">2025河南省选考科目要求</div>
      <el-select v-model="demand" size="mini" class="round_select" placeholder="请选择" @change="onChange">
        <el-option v-for="item in demand_list" :key="item" :label="item" :value="item" />
      </el-select>
      <div v-if="demand">
        <div class="table">
          <el-table :header-cell-style="{ 'color': '#A8A8A8', 'letter-spacing': '1px' }"
            :cell-style="{ 'color': 'black', height: '57px', 'line-height': '180%', padding: '4px 0' }"
            :data="tableData1" style="width: 100%;font-size:16px;text-align:center">
            <el-table-column prop="specialty_name" show-overflow-tooltip label="专业名称" />
            <el-table-column show-overflow-tooltip width="120" label="选考科目" align="right">
              <template>
                {{ demand }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page-pagination">
          <div class="pagination">
            <el-pagination :hide-on-single-page="true" :page-size="page.pageSize" :current-page="page.pageNum"
              :pager-count="11" :total="page.total" class="page-box" layout="prev, pager, next" prev-text='上一页'
              next-text='下一页' @current-change="pageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      demand: null,
      subjectList: null,
      demand_list: [],
      page: {
        pageSize: 8,
        pageNum: 1,
        total: 0,
      },
      tableData: [],
      tableData1: [],
      school_name: null

    };
  },
  computed: {
  },
  created() {
    this.school_name = this.$route.query.name
    if (this.school_name) {
      this.getPlanNum(this.school_name)
    }
  },
  methods: {
    getPlanNum(e) {
      if (e) {
        this.$fecth
          .post('school/getSpecialtyDemand', {
            name: e,
          })
          .then(res => {
            if (res.demand_list.length > 0) {
              res.demand_list.forEach(item => {
                if (item == '不提科目要求') {
                  item = '不限'
                }
                this.demand_list.push(item)
              })
              this.demand = res.demand_list[0]
              this.subjectList = res.list
              this.tableData = this.subjectList[this.demand]
              this.tableData1 = this.tableData.slice(0, 8)
              this.page.total = this.tableData.length
              if (this.demand == '不提科目要求') {
                this.demand = '不限'
              }
            }
          })
      }
    },
    onChange() {
      this.page.pageNum = 1
      let key = this.demand
      if (key == '不限') {
        key = "不提科目要求"
      }

      this.tableData = this.subjectList[key]
      this.page.total = this.tableData.length
      this.tableData1 = this.tableData.slice(0, 8)
    },
    pageChange(page) {
      this.tableData1 = this.tableData.slice(page * 8 - 8, page * 8)
    },
  },
};
</script>

<style scoped lang='less'>
.college-demand {
  position: relative;
  padding: 0 20px;
  padding-right: 0;

  .title {
    display: inline-block;
    font-size: 22px;
    color: white;
    height: 40px;
    line-height: 40px;
    padding: 0 25px 0 10px;
    border-radius: 5px;
    background: linear-gradient(287deg, transparent 10px, #1795FF 0, #0FBAFF);
    // background: linear-gradient(90deg, #0FBAFF 26%, #1795FF 93%);
  }

  .table {
    margin-top: 20px;
    height: 590px;
  }

  .specialty {
    display: inline-block;
    margin-right: 1px;
    line-height: 200%;
  }

}
</style>

<style lang="less">
.college-demand {
  .round_select {
    // position: relative;
    // top: -2px;
    width: 100px;
    margin-left: 30px;

    .el-input--mini .el-input__inner {
      background: #F8F8F8;
      border: 0;
      font-size: 16px;
      color: black;
    }

    .el-select .el-input .el-select__caret {
      color: black;
    }
  }

  .page-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;

    .pagination {
      padding: 40px 0;

      .page-box {
        text-align: center;
      }
    }
  }

  .page-box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-pager li {
      border-radius: 30px;
      margin-right: 8px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      list-style-type: none
    }

    .el-pager li.active {
      background: #4591F6;
      color: white;
    }
  }
}
</style>
