<template>
  <div>
    <div class="main" style="padding-top: 27px;">
      <div class="intro">
        <div class="logo">
          <img class="school_img" :src="this.$parent.$data.school.school_img" />
        </div>
        <div class="txt">
          <span @click="getDesc()">{{ this.$parent.$data.school.desc }}</span>
        </div>
      </div>

    </div>
    <div class="main" style="margin-top: 8px;padding-top: 20px;">
      <plan-list></plan-list>
    </div>

    <div class="main" style="margin-top: 8px;padding-top: 40px;padding-bottom: 15px;">
      <div class="title" style="color: #4591F6;font-size: 22px;">
        章程要点
      </div>
      <div style="color: #707070;margin-top: 15px;line-height: 24px; white-space: pre-wrap;"
        v-html="this.$parent.$data.school.lqyq">
      </div>
    </div>

    <div class="main">
      <div class="title" style="color: #4591F6;font-size: 22px; padding-top: 20px;">
        招生章程
      </div>
      <CollegeNews></CollegeNews>
    </div>


    <el-drawer title="院校详情" :visible.sync="drawer" :with-header="false">
      <div class="xiangqing" v-html="this.desc"></div>
    </el-drawer>
  </div>
</template>

<script>
import planList from './components/GetPlan.vue'
import CollegeNews from './components/CollegeNews.vue'
export default {
  name: "Info",
  components: {
    planList, CollegeNews
  },
  data() {
    return {
      desc: "",
      drawer: false,
    };
  },
  methods: {
    getDesc() {
      this.drawer = true;
      this.$fecth
        .post("school/schoolDesc", {
          school_id: this.$route.query.cid,
        })
        .then((res) => {
          this.desc = res;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes rainbow {
  0% {
    left: 0;
  }

  50% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

.main {
  width: 1200px;
  padding: 0 100px;
  margin: 0 auto;
  background: white;

  .intro {
    padding-bottom: 38px;

    .logo {
      width: 400px;
      height: 263px;
      padding-right: 30px;
      margin-right: 30px;
      display: inline-block;
      overflow: hidden;

      .school_img {
        position: relative;
        animation: rainbow 44s infinite;
        height: 100%;
      }
    }

    .txt {
      width: 60%;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      span {
        color: #4d4d4d;
        text-indent: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }
    }
  }

  .xiangqing {
    padding: 30px;
  }
}
</style>