<template>
  <div class="page-score">
    <div class="userInfo">
      <span>科类:</span>
      <span class="info_2">{{ userInfo.subject == 1 ? '物' : '史' }}<span
          v-for=" (item, key) in JSON.parse(userInfo.other_subject) " :key="key">
          <span v-if="item == 2">化</span>
          <span v-if="item == 3">生</span>
          <span v-if="item == 6">政</span>
          <span v-if="item == 5">地</span>
        </span>
      </span>

      <span>分数:</span>
      <span class="info_2">{{ userInfo.score }}分</span>
      <span>位次:&nbsp;</span>
      <span class="info_2">{{ equalScore.ranking }}</span>
      <span>换算位次</span>
      <span class="info_3">{{ equalScore.ranking_1 }}</span>
      <span class="info_4">/23年</span>
      <span class="info_3">{{ equalScore.ranking_2 }}</span>
      <span class="info_4">/22年</span>
      <span class="info_3">{{ equalScore.ranking_3 }}</span>
      <span class="info_4">/21年</span>
    </div>

    <div>
      <college-score></college-score>
    </div>
  </div>
</template>

<script>
import CollegeScore from './components/CollegeScore.vue'
import { mapState } from "vuex";
export default {
  name: '',
  computed: { ...mapState(["userInfo"]) },
  components: {
    CollegeScore
  },
  data() {
    return {
      equalScore: []
    };
  },
  watch: {
  },
  created() {
    this.$parent.cat = 2
    //设置批次 - 文理科
    this.is_wenli = this.userInfo.is_wenli
    // 获取等同分
    this.getEqualList();
  },
  methods: {

    // 获取等同分
    getEqualList() {
      this.$fecth
        .post("user_info/getRanking", {
          subject: this.userInfo.subject,
          score: this.userInfo.score,
        })
        .then((res) => {
          this.equalScore = res;
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page-score {
  width: 1200px;

  padding: 30px 100px;
  margin: 0 auto;
  background: white;


  .userInfo {
    text-align: center;
    background-color: #FFFCF4;
    padding: 6px;
    margin-bottom: 40px;

    .info_2 {
      color: #1787e0;
      margin-right: 25px;
    }

    .info_3 {
      color: #1787e0;
      margin-left: 15px;
    }

    .info_4 {
      color: #a5a3a2;
    }
  }

}
</style>
