<template>
  <div>
    <div class="title">
      <span class="name">双一流学科</span>
      <span class="title-t" :class="{ nav: nav == 1 }" @click="getNav(1)">第一轮</span>
      <span class="title-t" :class="{ nav: nav == 2 }" @click="getNav(2)">第二轮</span>
    </div>
    <span class="specialty_name" v-for="(item, i) in shuangyiliuList" :key="i">
      {{ item.specialty_name }}
    </span>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      list: [],
      shuangyiliuList: null,
      nav: null
    };
  },
  computed: {
  },
  created() {
    this.getShuang()
  },
  methods: {
    getShuang() {
      this.$fecth
        .get("school/getShuang", { params: { school_id: this.$route.query.cid } })
        .then((res) => {
          if (res.length > 0) {
            this.shuangyiliuList = res
            this.list = res
          } else {
            this.$emit('getshuangState', false)
          }
        });
    },
    getNav(e) {
      if (this.nav == e) {
        this.nav = null
        this.shuangyiliuList = this.list
      } else {
        this.nav = e
        let specialtyList = []
        this.list.forEach(element => {
          if (e == 1 && element.level == '第一轮') {
            specialtyList.push(element)
          }
          if (e == 2 && element.level == '第二轮') {
            specialtyList.push(element)
          }
        });
        this.shuangyiliuList = specialtyList
      }

    }
  },
};
</script>

<style scoped lang='less'>
.title {
  font-size: 22px;
  margin-bottom: 15px;

  .name {
    margin-right: 70px;
  }

  .title-t {
    border: 1px solid #4591F6;
    color: #4591F6;
    margin-right: 20px;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 18px;
  }
}

.specialty_name {
  background: #F5F5F5;
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;
  padding: 2px 5px;
  border-radius: 3px;
}

.nav {
  background: #4591F6;
  color: white !important;
}
</style>
