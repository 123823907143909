<template>
  <div class="news">
    <div class="left">
      <ul>
        <li v-for="(item, i) in newsList" :key="i">
          <el-row class="line">
            <el-col :span="4" class="time">{{ item.add_time.split(" ")[0] }}    </el-col>
            <el-col :span="12" class="title_n">
              <router-link :to="{
                path: 'solution',
                name: 'solution',
                query: {
                  nid: item.id,
                  cid: cid,
                  sid: sid,
                  degree: degree,
                },
              }">{{ item.title }}</router-link>
            </el-col>

          </el-row>
        </li>
      </ul>
    </div>
    <div class="right" style="
    overflow: hidden;">
      <img :src="news_thumb" alt="" style="height: 220px;
    width: 316px;">
    </div>
  </div>
</template>

<script>
export default {
  name: "CollegeNews",
  data() {
    return {
      newsList: [],
      news_thumb: "",
      cid: this.$route.query.cid,
      sid: this.$route.query.sid,
      degree: this.$route.query.degree,
    };
  },
  mounted() {
    this.$fecth
      .post("school_news/getData", {
        school_id: this.$route.query.cid,
        type: 1,
        pageNum: 1,
        pageSize: 4,
      })
      .then((res) => {
        this.newsList = res.lists;
        this.news_thumb = res.img;
      });
  },
};
</script>

<style lang="less" scoped>
a {
  color: #4d4d4d;
}

.news {
  color: #4d4d4d;
  position: relative;

  .left {
    display: inline-block;
    width: 700px;
    padding-bottom: 30px;
    min-height: 260px;
  }

  .right {
    display: inline-block;
    width: 350px;
    position: absolute;
    overflow: hidden;
    right: 20px;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  li {
    line-height: 33px;
    list-style: none;
  }

  .line {
    line-height: 55px;
    border-bottom: 1px solid #E9E9E9;
    .title_n {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      white-space: nowrap;
      -webkit-box-orient: vertical;
    }
  }
}
</style>