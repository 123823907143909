<template>
  <div class="college-specialty-ranking">
    <div class="title">学科评估</div>
    <div class="title-remarks">教育部第四轮结果评估</div>
    <div>
      <div class="table">
        <el-table :header-cell-style="{ 'color': '#A8A8A8', 'letter-spacing': '1px' }"
          :cell-style="{ 'color': 'black', height: '47px', 'line-height': '180%', padding: '4px 0' }" :data="tableData"
          style="width: 100%;font-size:16px;text-align:center">
          <el-table-column prop="specialty_name" show-overflow-tooltip label="学科" />
          <el-table-column prop="result" show-overflow-tooltip label="评估结果" align="center" />
        </el-table>
      </div>
      <div class="page-pagination">
        <div class="pagination">
          <el-pagination :hide-on-single-page="true" :page-size="page.pageSize" :current-page="page.pageNum"
            :pager-count="11" :total="page.total" class="page-box" layout="prev, pager, next" prev-text='上一页'
            next-text='下一页' @current-change="pageChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      page: {
        pageSize: 9,
        pageNum: 1,
        total: 0,
      },
      tableData: [],

    };
  },
  computed: {
  },
  created() {
    this.getPlanNum()
  },
  methods: {
    getPlanNum() {
      this.$fecth
        .post('school/getSpecialtyAssess', {
          school_id: this.$route.query.cid,
          pageSize: this.page.pageSize,
          pageNum: this.page.pageNum,
        })
        .then(res => {
          this.tableData = res.list
          this.page.total = res.count
        })
    },

    pageChange(page) {
      this.page.pageNum = page
      this.getPlanNum()
    },
  },
};
</script>

<style scoped lang='less'>
.college-specialty-ranking {
  position: relative;
  padding: 13px 20px 0 40px;
  height: 620px;

  .title {
    font-size: 22px;
    color: black;
    margin-bottom: 10px;
  }

  .title-remarks {
    color: #A8A8A8;
    font-size: 18px;
  }

  .table {
    margin-top: 10px;
  }
}
</style>

<style lang="less">
.college-specialty-ranking {
  .page-pagination {
    position: absolute;
    width: 100%;
    bottom: -40px;

    .pagination {
      padding: 40px 0;

      .page-box {
        text-align: center;
      }
    }
  }

  .page-box {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-pager li {
      border-radius: 30px;
      margin-right: 8px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      list-style-type: none
    }

    .el-pager li.active {
      background: #4591F6;
      color: white;
    }
  }
}
</style>
