<template>
  <div class="college-score-page">
    <div class="search-filter">
      <el-row>
        <el-col :span="3">
          <span class="title">
            院校分数线
          </span>
        </el-col>
        <el-col :span="20">
          <el-select v-model="year" style="width:120px;margin-right: 20px;" size="mini" class="round_select"
            placeholder="请选择" @change="onChange">
            <el-option v-for="item in yearList" :key="item" :label="item" :value="item" />
          </el-select>
          <el-select v-model="batch" style="width:130px;margin-right: 20px;" size="mini" class="round_select"
            placeholder="请选择" @change="onChange">
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>

        </el-col>
      </el-row>
    </div>
    <div class="college-item">
      <span class="title">招生单位</span>
      <span class="school_name" @click="changeCollege('', null)" :class="{ hove: nav == null }">全部</span>
      <span class="school_name" v-for="(item, i) in NavList" :class="{ hove: nav == i }" :key="i"
        @click="changeCollege(item, i)">{{ item.school_id }} &nbsp;{{ item.school_name }}</span>
    </div>

    <div class="college-table">
      <template>
        <el-table :data="tableData"
          :header-cell-style="{ 'color': '#A8A8A8', 'letter-spacing': '1px', 'font-size': '18px', 'font-weight': '400' }"
          style="width: 100%" :cell-style="{ 'font-size': '18px', 'color': 'black' }">
          <el-table-column prop="year" label="年份" width="80">
          </el-table-column>
          <el-table-column prop="school_name" label="单位名称" width="280">
          </el-table-column>
          <el-table-column prop="max_score" label="最高分" align="center">
          </el-table-column>
          <el-table-column prop="max_ranking" label="最高位次" align="center">
          </el-table-column>
          <el-table-column prop="mean_score" label="平均分" align="center">
          </el-table-column>
          <el-table-column prop="min_score" label="最低分" align="center">
          </el-table-column>
          <el-table-column prop="ranking" label="最低位次" align="center">
          </el-table-column>
          <el-table-column prop="difference" label="线差" align="center">
          </el-table-column>
          <el-table-column prop="plan_num" label="计划数" align="center">
          </el-table-column>
          <el-table-column prop="matriculate" label="录取数" align="center">
          </el-table-column>
        </el-table>
      </template>
    </div>

    <div class="text" style="width: 1400px;position: relative ; left:-100px; height: 7px;background-color: #f4f4f4;">
    </div>

    <div class="search-filter" style="margin-top: 40px;">


      <el-row>
        <el-col :span="3">
          <span class="title">
            专业分数线
          </span>
        </el-col>
        <el-col :span="20">
          <el-select v-model="score_order" placeholder="排序方式" class="round_select" @change="orderScore" size="mini">
            <el-option label="最低分升序" value="asc" />
            <el-option label="最低分降序" value="desc" />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div class="specialty-table">
      <template>
        <el-table :data="tableData1"
          :header-cell-style="{ 'color': '#A8A8A8', 'letter-spacing': '1px', 'font-size': '18px', 'font-weight': '400' }"
          style="width: 100%;min-height: 528px;" :cell-style="{ 'font-size': '18px', 'color': 'black' }">
          <el-table-column prop="year" show-overflow-tooltip label="年份" width="80">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="单位名称" width="230">
          </el-table-column>
          <el-table-column prop="specialty_name" show-overflow-tooltip label="专业名称" width="280">
          </el-table-column>
          <el-table-column prop="max_score" label="最高分" align="center">
          </el-table-column>
          <el-table-column prop="max_ranking" label="最高位次" width="96" align="center">
          </el-table-column>
          <el-table-column prop="mean_score" label="平均分" align="center">
          </el-table-column>
          <el-table-column prop="min_score" label="最低分" align="center">
          </el-table-column>
          <el-table-column prop="ranking" label="最低位次" width="96" align="center">
          </el-table-column>
          <el-table-column prop="plan_num" label="计划数" align="center">
          </el-table-column>
          <el-table-column prop="matriculate_num" label="录取数" align="center">
          </el-table-column>
        </el-table>
      </template>

      <!--        分页-->
      <div class="pagination">
        <el-pagination :hide-on-single-page="true" :page-size="page.pageSize" :current-page="page.pageNum"
          :pager-count="11" :total="page.total" class="page-box" layout="prev, pager, next" prev-text='上一页'
          next-text='下一页' @current-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: '',
  computed: { ...mapState(["userInfo"]) },
  data() {
    return {
      batch: 1,
      batchList: [
        { id: 1, name: "本科一批", code: "1", status: "1" },
        { id: 2, name: "本科二批", code: "2", status: "2" },
        { id: 3, name: "高职高专批", code: "3", status: "3" },
      ],
      year: "2024", // 年份
      yearList: [
        "2024",
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
      ],
      score_order: null,
      NavList: null,
      nav: null,
      num: 1,
      select_code: null,
      tableData: null,
      tableData1: null,

      page: {
        pageSize: 20,
        pageNum: 1,
        total: 0,
      },
    };
  },
  mounted() {
    this.school_id = this.$route.query.sid;
    if (this.school_id == "") {
      return false;
    }
    this.is_wenli = this.userInfo.is_wenli;
    this.getNav();
  },
  methods: {
    getNav() {
      if (!this.school_id) {
        return;
      }
      this.scoreLine = [];
      this.$fecth
        .get("show_school_score/getNav", {
          params: {
            batch: this.batch,
            is_wenli: this.is_wenli,
            school_id: this.school_id,
            type: 1
          },
        })
        .then((res) => {
          if (res.length > 0) {
            this.NavList = res;
            if (this.NavList[0]) {
              this.select_code = this.NavList.map(row => row['select_code'])
              this.batch = Number(this.NavList[0].batch);
              this.getScore();
            }
          }
        });
    },
    onChange() {
      this.getScore()
    },
    //招生单位选择
    changeCollege(item, i) {
      if (item) {
        this.nav = i
        this.select_code = item.select_code
        this.batch = item.batch
      } else {
        this.nav = null
        if (this.NavList) {
          this.select_code = this.NavList.map(row => row['select_code'])
        }
      }
      this.getScore()
    },

    getScore() {
      this.page.pageNum = 1
      this.getCollegeScore()
      this.getSpecialtyScore()
    },

    //获取院校分数
    getCollegeScore() {
      if (this.select_code == "" || this.select_code == null) {
        return false;
      }
      this.$fecth
        .post("show_school_score/getCollegeScore", {
          pageSize: 100,
          year: this.year,
          batch: this.batch,
          is_wenli: this.is_wenli,
          select_code: this.select_code,
        })
        .then((res) => {
          this.tableData = res.list;
        });
    },
    //获取专业分数
    getSpecialtyScore() {
      if (this.select_code == "" || this.select_code == null) {
        return false;
      }
      this.$fecth
        .post("show_school_score/getMajorData", {
          year: this.year,
          batch: this.batch,
          is_wenli: this.is_wenli,
          select_code: this.select_code,
          order: this.score_order,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
        })
        .then((res) => {
          this.tableData1 = res.list;
          this.page.total = res.count
        });
    },

    pageChange(page) {
      this.page.pageNum = page
      this.getSpecialtyScore()
    },

    orderScore() {
      this.page.pageNum = 1
      this.getSpecialtyScore()
    }
  },
};
</script>

<style scoped lang='less'>
.college-score-page {
  margin-top: 20px;

  .search-filter {
    .title {
      font-size: 22px;
    }
  }

  .college-item {
    margin-top: 20px;
    margin-bottom: 10px;


    .title {
      font-size: 18px;
      font-weight: normal;
      line-height: 18px;
      color: #519DEB;
      margin-right: 30px;

    }

    .school_name {
      color: #A7A7A7;
      border: 1px solid #A7A7A7;
      margin-right: 20px;
      font-size: 18px;
      padding: 2px 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      display: inline-block;
    }

    .hove {
      color: #519DEB;
      border: 1px solid #519DEB;

    }
  }

  .college-table {
    padding-bottom: 40px;
  }

  .specialty-table {
    margin-top: 25px;
    padding-bottom: 120px;
  }

  //分页
  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }
}
</style>

<style lang='less'>
.college-score-page {
  .search-filter {
    .el-input--mini .el-input__inner {
      background: #F8F8F8;
      border: 0;
      font-size: 16px;
      color: black;
    }

    .el-select .el-input .el-select__caret {
      color: black;
    }
  }

  .page-box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-pager li {
      border-radius: 30px;
      margin-right: 8px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      list-style-type: none
    }

    .el-pager li.active {
      background: #4591F6;
      color: white;
    }
  }
}
</style>
