import { render, staticRenderFns } from "./look-college.vue?vue&type=template&id=05458e48&scoped=true&"
import script from "./look-college.vue?vue&type=script&lang=js&"
export * from "./look-college.vue?vue&type=script&lang=js&"
import style0 from "./look-college.vue?vue&type=style&index=0&id=05458e48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05458e48",
  null
  
)

export default component.exports