<template>
  <div style="background-color: #f4f4f4;">
    <div class="college">
      <div class="top">
        <el-row class="school_info">
          <el-col :span="20">
            <div>
              <span style="font-size: 22px; padding-right: 20px;color: black;">{{
                school.name
              }}</span>
              <span v-for="(item, i) in school.school_tip_id" :key="i" style="
                  border: 1px solid rgb(255 255 255 / 24%);
                  margin-right: 3px;
                  padding: 2px 2px;
                ">
                {{ item }}
              </span>
              <!-- <span style="padding-right: 130px">{{ school.address }}</span> -->
            </div>

            <div style="margin: 20px 0  20px">
              <a v-if="school.school_url" :href="school.school_url" target="_blank"
                style="width: 340px; margin-right: 20px; white-space:nowrap;overflow: hidden; text-overflow: ellipsis; ">
                官方网站:{{ school.school_url }}
              </a>
              <a v-if="school.school_admissions_url" :href="school.school_admissions_url" target="_blank"
                style="width: 360px; white-space:nowrap;overflow: hidden; text-overflow: ellipsis; ">
                招生网站:{{ school.school_admissions_url }}
              </a>
            </div>


            <div style="margin: 15px 0">
              <span
                style="width: 340px; margin-right: 20px; white-space:nowrap;overflow: hidden; text-overflow: ellipsis; display: inline-block ">详细地址:
                {{ school.full_address }}</span>
              <span
                style="width: 260px; white-space:nowrap;overflow: hidden; text-overflow: ellipsis; display: inline-block ">咨询电话：{{
                  school.phone }}</span>

              <a v-if="school.school_panoramic" :href="school.school_panoramic" target="_blank"
                style="width: 260px; white-space:nowrap;overflow: hidden; text-overflow: ellipsis; display: inline-block ">
                全景校园
              </a>
            </div>

            <div class="info-card">
              <div class="item">
                <p class="content" v-if="school.found_time" style="color: #4591F6;font-size: 30px;">
                  {{ school.found_time }}
                </p>
                <p v-else>暂无</p>
                <p class="title">创建时间</p>
              </div>

              <div class="item">
                <div class="content marquee " style="color: #4591F6;font-size: 30px;">
                  <div class="marquee-wrap">
                    <div class="marquee-content">
                      {{ school.area }}<span style="color: #4591F6;font-size: 18px;">亩</span>
                    </div>
                  </div>
                </div>
                <p class="title">占地面积</p>
              </div>
              <div class="item">
                <p class="content" style="color: #4591F6;font-size: 30px;">{{ school.fees }}<span
                    style="color: #4591F6;font-size: 18px;" v-if="school.fees">元</span><span
                    style="color: #4591F6;font-size: 18px;" v-else>/</span>
                </p>

                <p class="title">最低住宿费</p>
              </div>
              <div class="item" v-if="school.nature">
                <div class="content marquee ">
                  <div class="marquee-wrap">
                    <div class="marquee-content">
                      {{ school.nature.name }}
                    </div>
                  </div>
                </div>
                <p class="title">院校性质</p>
              </div>
              <div class="item">
                <p class="content">{{ school.degree == 0 ? '本科' : '专科' }}</p>
                <p class="title">院校层次</p>
              </div>
              <div class="item">
                <p class="content">{{ school.school_type }}</p>
                <p class="title">院校类型</p>
              </div>
              <div class="item">
                <div class="content marquee ">
                  <div class="marquee-wrap">
                    <div class="marquee-content">
                      {{ school.school_belong }}
                    </div>
                  </div>
                </div>
                <p class="title">主管部门</p>
              </div>

              <div class="item item-1" v-if="school.baoyan">
                <p class="content">有</p>
                <p class="title">保研资格</p>
              </div>

              <div class="item item-1" v-if="school.shuoshi" @click="choseNav(1)">
                <p class="content" style="color: #4591F6;font-size: 30px;">{{ school.shuoshi }}</p>
                <p class="title">硕士专业</p>
              </div>
              <div class="item item-1" v-if="school.boshi" @click="choseNav(1)">
                <p class="content" style="color: #4591F6;font-size: 30px;">{{ school.boshi }}</p>
                <p class="title">博士专业</p>
              </div>

              <div class="item item-1" v-if="school.ruanke">
                <p class="content" style="color: #4591F6;font-size: 30px;">{{ school.ruanke }}</p>
                <p class="title">院校排名</p>
              </div>

            </div>

          </el-col>
          <el-col :span="4">
            <img :src="school.logo" alt="" style="
              width: 120px;
              position: relative;
              border-radius: 100px;
              top: 11px;
              left: 70px;
            " />
          </el-col>
        </el-row>
      </div>

      <div class="page-college">
        <div class="nav">
          <span @click="choseNav(0)" :class="{ cat: this.cat == 0 }">院校概况</span>
          <span @click="choseNav(1)" :class="{ cat: this.cat == 1 }">专业概况</span>
          <span @click="choseNav(2)" :class="{ cat: this.cat == 2 }">历年数据</span>
          <span @click="choseNav(3)" :class="{ cat: this.cat == 3 }">提前批分数线</span>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>

</template>

<script>
export default {
  name: "College",
  data() {
    return {
      school_id: "",
      cat: 0,
      school: [],
    };
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //获取学校信息
    this.$fecth
      .get("school/schoolIndex", { params: { id: this.$route.query.cid } })
      .then((res) => {
        this.school = res;
        if (res.school_id) {
          // 获取院校分数线
          this.school_id = res.school_id;
        }
      });
  },
  methods: {
    choseNav(e) {
      this.cat = e;
      switch (e) {
        case 0:
          this.$router
            .push({
              path: "/college/",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.school.degree,
                name: this.school.name,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 1:
          this.$router
            .push({
              path: "/college/specialty",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.school.degree,
                name: this.school.name,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 2:
          this.$router
            .push({
              path: "/college/score",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.school.degree,
                name: this.school.name,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        case 3:
          this.$router
            .push({
              path: "/college/score-other",
              query: {
                cid: this.$route.query.cid,
                sid: this.school.school_id,
                degree: this.school.degree,
                name: this.school.name,
              },
            })
            .catch(() => {
              console.log("重复点击按钮");
            });
          return;
        default:
          return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.college {
  background: #f4f4f4;

  .top {
    background: linear-gradient(103deg, #B4F8FE -11%, #EEFCFE 20%, #FFFFFF 62%, #FFF9E3 114%);
    min-height: 216px;

    .school_info {
      width: 1400px;
      padding: 0 100px;
      margin: 0 auto;
      padding-top: 20px;
      color: #707070;
      position: relative;
      padding-bottom: 80px;

      a {
        color: #707070;
        display: inline-block;
        text-decoration: none;
      }

      .info-card {
        position: absolute;
        bottom: -30px;

        .item {
          display: inline-block;
          background: white;

          text-align: center;
          width: 100px;
          height: 90px;
          border-radius: 10px;
          margin-right: 7px;
          box-shadow: 0px 2px 3px 0px #D6FBFE;
          position: relative;

          .content {
            color: #4C4C5F;
            font-size: 20px;
            text-align: center;
            width: 100%;
            position: absolute;
            bottom: 36px;
          }

          .title {
            color: #A7A7A7;
            font-size: 16px;
            text-align: center;
            width: 100%;
            position: absolute;
            bottom: 10px;
          }

        }

        .item-1 {
          width: 85px;
        }

        .item-last {
          width: 80px;
          margin-right: 0;
        }
      }
    }
  }

  .guanzhu {
    float: right;
    position: relative;
    top: 10px;
    right: 40px;
  }

  .page-college {
    width: 1400px;
    margin: 0 auto;
    background: white;
  }

  .nav {
    width: 1200px;
    margin: 0 auto;
    font-size: 18px;
    padding-top: 60px;

    border-bottom: 2px solid #4591F6;

    span {
      display: inline-block;
      padding: 10px 32px;
      margin-right: 30px;

      &:hover {
        color: white;
        border-radius: 7px 7px 0 0;
        background: #4591F6;
      }
    }

    .cat {
      color: white;
      border-radius: 7px 7px 0 0;
      background: #4591F6;
    }
  }
}

.tip {
  background: url("./../../assets/img/tip_new.png") no-repeat !important;
  background-position: right top !important;
}

.tips {
  background: #519cea url("./../../assets/img/tip_new.png") no-repeat !important;
  background-position: right top !important;
}
</style>


<style>
.marquee {
  overflow: hidden;
}

.marquee .marquee-wrap {
  white-space: nowrap;
  width: 100%;
  animation: marquee-wrap 4s infinite linear;
}

.marquee .marquee-content {
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 4s infinite linear;
}

@keyframes marquee-wrap {

  0%,
  30% {
    transform: translateX(0);
  }

  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {

  0%,
  30% {
    transform: translateX(0);
  }

  70%,
  100% {
    transform: translateX(-100%);
  }
}
</style>