<template>
  <div>
    <div class="main">
      <h1 class="title">{{ info.title }}</h1>
      <p class="hot">
        <i class="iconfont icon-huo" />
        <span v-if="info.add_time" class="time">{{
          info.add_time.split(" ")[0]
        }}</span>
      </p>
      <div v-if="info.content" class="content" style='white-space: pre-wrap;' v-html="info.content" />
      <div v-else class="content">
        <h2 style="text-align: center">暂无内容！</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      id: -1,
      info: {},
    };
  },
  watch: {
    $route() {
      this.id = this.$route.query.cid;
      this.$fecth
        .get("school/getSchoolNewsByid", {
          params: {
            schoolId: this.$route.query.cid,
            newsId: this.$route.query.nid,
          },
        })
        .then((res) => {
          this.info = res;
        });
    },
  },
  mounted() {
    this.id = this.$route.query.cid;
    this.$fecth
      .post("school_news/getDataById", {
        school_id: this.$route.query.cid,
        id: this.$route.query.nid,
      })
      .then((res) => {
        this.info = res;
      });
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 1340px;
  padding: 30px;
  margin: auto;
  background-color: #ffffff;

  .title {
    text-align: center;
  }

  .hot {
    text-align: right;
    color: #8c939d;
    .time {
      margin-left: 20px;
    }
  }
  .content {
    margin: 20px 0;
  }
  .content p {
    background: red !important   ;
  }
}
</style>
