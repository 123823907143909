<template>
  <div class="page">
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="nav_">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>智能查询</el-breadcrumb-item>
          <el-breadcrumb-item>查专业</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>

      <el-col :span="7" :offset="5">
        <div class="search">
          <el-autocomplete v-model="kwd" style="width:220px" :fetch-suggestions="querySearch" :trigger-on-focus="false"
            class="condition-search" prefix-icon="el-icon-search" popper-class="my-autocomplete" placeholder="请输入内容"
            @keyup.native.enter="toSearch" @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div
          style="display: inline-block;background:#1787e0;border: 1px solid rgb(220, 223, 230); border-radius:0 4px 4px 0height: 40px;width: 50px;line-height: 40px;position: relative;left: 50px;">
          <img style="margin-left: 16px;vertical-align: middle;" src="@/assets/img/mojar/mj-search.png" alt="" />
        </div>

      </el-col>
    </el-row>
    <div class="name">
      <el-row>
        <el-col :span='2'>
          <router-link to="lookSpecialty" style="color:white"> &lt;</router-link>
        </el-col>
        <el-col :span='18'>
          {{ specialty.name }}
        </el-col>
      </el-row>

    </div>

    <div class="tabs">
      <span :class="{ tab: true, act: tabID === 0 }" @click="tabChange(0)">专业概况</span>
      <span :class="{ tab: true, act: tabID === 1 }" @click="tabChange(1)">就业前景</span>
      <span :class="{ tab: true, act: tabID === 2 }" @click="tabChange(2)" v-if="specialty.degree == 0">相关程度</span>
      <span :class="{ tab: true, act: tabID === 3 }" @click="tabChange(3)">较好院校</span>
    </div>

    <div v-if="tabID == 0">
      <div class="title">
        <span></span> <span>专业概况</span>
      </div>
      <div class="info">
        <div v-if="specialty.degree == 0">
          <div class="d1">
            <el-col :span="8">
              <div class="info_bg info_bg1"></div>
            </el-col>
            <el-col :span="16">学历层次：本科</el-col>
          </div>
        </div>

        <div v-if="specialty.degree == 1">
          <div class="d1">
            <el-col :span="8">
              <div class="info_bg info_bg6"></div>
            </el-col>
            <el-col :span="16">学历层次：专科</el-col>
          </div>
        </div>

        <div v-if="specialty.degree == 2">
          <div class="d1">
            <el-col :span="8">
              <div class="info_bg info_bg6"></div>
            </el-col>
            <el-col :span="16">学历层次：职业本科</el-col>
          </div>
        </div>

        <div class="d1">
          <el-col :span="8">
            <div class="info_bg info_bg2"></div>
          </el-col>
          <el-col :span="16">修学年限：{{ specialty.study_year }}</el-col>
        </div>
        <div v-if="specialty.degree == 0">
          <div class="d1">
            <el-col :span="8">
              <div class="info_bg info_bg3"></div>
            </el-col>
            <el-col :span="16">授予学位：{{ specialty.qualification }}</el-col>
          </div>

        </div>

        <div class="d1" v-if="specialty.degree != 2">
          <el-col :span="8">
            <div class="info_bg info_bg4"></div>
          </el-col>
          <el-col :span="16">文理比例：{{ specialty.wenli_proportion }}</el-col>
        </div>
        <div class="d1" v-if="specialty.degree != 2">
          <el-col :span="8">
            <div class="info_bg info_bg5"></div>
          </el-col>
          <el-col :span="16">男女比例：{{ specialty.nannv_proportion }}</el-col>
        </div>

        <div class="d1" v-if="specialty.add_time">
          <el-col :span="8">
            <div class="info_bg info_bg7"></div>
          </el-col>
          <el-col :span="16">增设年份：{{ specialty.add_time }}</el-col>
        </div>

      </div>

      <div v-if="specialty.degree == 0">
        <div class="title">
          <span></span> <span>专业背景</span>
        </div>
        <div class="intro-content">
          <p v-if="specialty.introduction">{{ specialty.introduction }}</p>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div class="title">
        <span></span> <span>培养目标</span>
      </div>
      <div class="intro-content">
        <p v-if="specialty.train_objective">{{ specialty.train_objective }}</p>
        <p v-else>暂无！</p>
      </div>

      <div v-if="specialty.degree == 1">
        <div class="title">
          <span></span> <span>培养知识</span>
        </div>
        <div class="intro-content">
          <div v-if="specialty.pyzs">
            <p v-for="(key, i) in pyzsData" :key="i">{{ key }}</p>
          </div>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div v-if="specialty.degree == 1">
        <div class="title">
          <span></span> <span>培养能力</span>
        </div>
        <div class="intro-content">
          <div v-if="specialty.pynl">
            <p v-for="key in pynlData" class="intro-content" :key="key">{{ key }}</p>
          </div>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div v-if="specialty.degree == 0">
        <div class="title">
          <span></span> <span>培养要求</span>
        </div>
        <div class="intro-content">
          <p v-if="specialty.train_requirements">{{ specialty.train_requirements }}</p>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div v-if="specialty.degree == 0">
        <div class="title">
          <span></span> <span>学习门槛</span>
        </div>
        <div class="intro-content">
          <p v-if="specialty.discipline_required">{{ specialty.discipline_required }}</p>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div v-if="specialty.degree == 0">
        <div class="title">
          <span></span> <span>考研方向</span>
        </div>
        <div class="intro-content">
          <p v-if="specialty.pg_direction">{{ specialty.pg_direction }}</p>
          <p v-else>暂无！</p>
        </div>
      </div>

      <div class="title">
        <span></span> <span>主要课程</span>
      </div>
      <div class="intro-content">
        <p v-if="specialty.main_course">{{ specialty.main_course }}</p>
        <p v-else>暂无！</p>
      </div>

      <div v-if="specialty.degree == 1">
        <div class="title">
          <span></span> <span>接续本科专业举例</span>
        </div>
        <div class="intro-content">
          <div v-if="specialty.jxbkzy">
            {{ specialty.jxbkzy }}
          </div>
          <p v-else>暂无！</p>
        </div>
      </div>
    </div>

    <jiu-ye v-if="tabID == 1" :specialty='specialty' :num1='num1' :num2='num2'></jiu-ye>

    <div v-else-if="tabID === 2">
      <div class="title">
        <span></span> <span>与高中科目相关程度</span>
      </div>

      <div class="box" style="margin-top:20px">
        <p class="box-title">语文</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(yuwen * 100)" />
        <p class="box-title">数学</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(shuxue * 100)" />
        <p class="box-title">英语</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(yingyu * 100)" />
        <p class="box-title">物理</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(wuli * 100)" />
        <p class="box-title">化学</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(huaxue * 100)" />
        <p class="box-title">生物</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(shengwu * 100)" />
        <p class="box-title">政治</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(zhengzhi * 100)" />
        <p class="box-title">历史</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(lishi * 100)" />
        <p class="box-title">地理</p>
        <el-progress :text-inside="true" :stroke-width="26" :percentage="Math.floor(dili * 100)" />
      </div>
    </div>

    <div v-else-if="tabID === 3">
      <div class="title">
        <span></span> <span>开设本专业较好的院校</span>
      </div>
      <p v-if="specialty.best_school" class="intro-content">{{ specialty.best_school }}</p>
      <p v-else class="intro-content">暂无！</p>
    </div>

  </div>
</template>

<script>
import JiuYe from "./components/JiuYe.vue";
export default {
  name: "",
  data() {
    return {
      sid: 0,
      degree: 0,
      level: [],
      children: [],
      levelId: 0,
      levelId1: 0,
      sname: "",
      specialty: [],
      kwd: "",
      pyzsData: [],
      pynlData: [],
      tabID: 0,
    };
  },
  components: {
    JiuYe,
  },
  mounted() {
    this.sid = this.$route.query.sid;
    this.getSpecialty();
  },
  methods: {
    getSpecialty() {
      this.$fecth
        .post("Specialty/getSpecialtyOverview", { specialty_code: this.sid })
        .then((res) => {
          this.specialty = res;
          this.pyzsData = [];
          this.pynlData = [];
          if (this.specialty.pyzs) {
            this.pyzsData = this.specialty.pyzs.split("\n");
          }
          if (this.specialty.pynl) {
            this.pynlData = this.specialty.pynl.split("\n");
          }
        });
    },
    handleSelect(item) {
      this.kwd = item.name;
      this.$router.push({
        path: "/specialtyInfo",
        query: { sid: item.specialty_code },
      });
    },
    querySearch(queryString, cb) {
      if (queryString.trim()) {
        this.$fecth
          .get("specialty/searchSpecialtyName", {
            params: { name: queryString },
          })
          .then((res) => {
            if (res.lists.length > 0) {
              // 调用 callback 返回建议列表的数据
              cb(res.lists);
            }
          });
      }
    },
    toSearch() {
      this.$router.push({ path: "/search", query: { kwd: this.kwd, type: 1 } });
    },
    tabChange(id) {
      this.tabID = id;
      if (id == 1) {
        this.num1 = this.specialty.shenzaolv;
        this.num2 = this.specialty.jiuyelv;
      }
      if (id == 2) {
        this.yuwen = 0;
        this.shuxue = 0;
        this.yingyu = 0;
        this.wuli = 0;
        this.huaxue = 0;
        this.shengwu = 0;
        this.dili = 0;
        this.lishi = 0;
        this.zhengzhi = 0;
        setTimeout(() => {
          this.yuwen = this.specialty.yuwen;
          this.shuxue = this.specialty.shuxue;
          this.yingyu = this.specialty.yingyu;
          this.wuli = this.specialty.wuli;
          this.huaxue = this.specialty.huaxue;
          this.shengwu = this.specialty.shengwu;
          this.dili = this.specialty.dili;
          this.lishi = this.specialty.lishi;
          this.zhengzhi = this.specialty.zhengzhi;
          this.$forceUpdate();
        }, 100);
      }
    },
  },
};
</script>

<style scoped lang='less'>
.tabs {
  margin-top: 20px;
  border-bottom: 1px solid #f2f2f2;

  .tab {
    display: inline-block;
    font-size: 18px;
    padding: 18px 54px;
    transition: all 0.3s;

    &:hover {
      color: #1787e0;
    }

    &.act {
      color: #1787e0;
      border-bottom: 1px solid #1787e0;
    }
  }
}

.nav_ {
  font-size: 16px;
  padding: 20px 0;
  color: #666666;
  width: 70%;
  display: inline-block;
}

.search {
  width: 40%;
  display: inline-block;
}

.name {
  font-size: 18px;
  color: white;
  background: #1787e0;
  line-height: 85px;
  position: relative;
  text-align: center;
  letter-spacing: 2px;
}

.page {
  margin-bottom: 20px;
}

.info {
  // display: flex;
  padding: 20px;
  display: inline-grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-template-rows: 33.33% 33.33% 33.33%;
  grid-row-gap: 30px;
  width: 100%;

  .d1 {
    display: inline-block;
    width: 330px;
    background: #59adf8;
    height: 60px;
    line-height: 60px;
    color: white;
    border-radius: 3px;

    // margin-right: 40px;
    .info_bg {
      width: 60px;
      height: 60px;
      margin-left: 15px;
    }

    .info_bg1 {
      background: url("../../assets/img/mojar/info_bg.png") -26px 148px;
    }

    .info_bg2 {
      background: url("../../assets/img/mojar/info_bg.png") -242px 68px;
    }

    .info_bg3 {
      background: url("../../assets/img/mojar/info_bg.png") -244px 148px;
    }

    .info_bg4 {
      background: url("../../assets/img/mojar/info_bg.png") -430px 148px;
    }

    .info_bg5 {
      background: url("../../assets/img/mojar/info_bg.png") -430px 62px;
    }

    .info_bg6 {
      background: url("../../assets/img/mojar/info_bg.png") -16px 70px;
    }

    .info_bg7 {
      background: url("../../assets/img/mojar/info_bg1.png") -25px 58px;
    }
  }
}

.title {
  margin-top: 15px;

  span:first-child {
    border-left: 5px solid #1787e0;
    height: 16px;
  }

  span:last-child {
    font-size: 18px;
    margin-left: 10px;
    position: relative;
    top: 1px;
  }
}

.intro-content {
  color: #666666;
  padding: 30px 20px;
  font-size: 14px;
}
</style>
